@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  img {
    display: inline-block;
  }
  a {
    text-decoration: none;
    color: #664dfb;
  }
}
